<template>
    <div class="login">
        <b-modal v-model="loginErrorModal" hide-header hide-footer>
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2>Attention !</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 text-center">
                    <p>{{ errorMessage }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 text-center">
                    <b-button
                        class="custom-btn-color-secondary"
                        v-on:click="loginErrorModal = !loginErrorModal"
                        >Fermer</b-button
                    >
                </div>
            </div>
        </b-modal>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="custom-h1">
                        <img
                            src="../../../public/header_holeffect.png"
                            class="img-fluid"
                        />
                    </h1>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <h2 class="custom-color-primary custom-h2">Accès Client</h2>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6"></div>
                <div class="col-lg-2 text-right">
                    <p class="custom-color-secondary">*Champs requis</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Identifiant*"
                        class="custom-form-input"
                        v-model="userName"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-form-input
                        placeholder="Mot de passe*"
                        class="custom-form-input"
                        :type="type"
                        v-model="password"
                    ></b-form-input>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <span v-on:click="showPassword" class="pointer">
                        Mot de passe <b-icon-eye></b-icon-eye>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-5"></div>
                <div class="col-lg-2">
                    <hr class="custom-hr" />
                </div>
                <div class="col-lg-5"></div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                    <b-button
                        class="custom-btn-color-secondary"
                        v-on:click="login"
                        >Se connecter</b-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RSAUtils from "../../assets/lib/security";

export default {
    data: function () {
        return {
            user: { userId: "1555" },
            rsa: "",
            userName: "",
            password: "",
            loginErrorModal: false,
            errorMessage: "",
            type: "password",
        };
    },
    async beforeMount() {
        this.rsa = await this.$store.dispatch("rsa/getRSA");
    },
    methods: {
        showPassword: function () {
            if (this.type == "password") {
                this.type = "text";
            } else {
                this.type = "password";
            }
        },
        goRegister: function () {
            this.$router.push({ name: "Register" });
        },
        goResetPassword: function () {
            this.$router.push({ name: "ResetPassword" });
        },
        login: async function () {
            let key = new RSAUtils.getKeyPair(
                this.rsa.pubexponent,
                "",
                this.rsa.pubmodules
            );
            let reversedPwd = this.password.split("").reverse().join("");

            var encrypedPwd = RSAUtils.encryptedString(key, reversedPwd);

            let response = await this.$store.dispatch("user/login", {
                userName: this.userName,
                password: encrypedPwd,
            });
            if (response.result == "0") {
                await this.$store.dispatch("user/getAfterLogin", {
                    userName: this.userName,
                });
                this.$router.push({ name: "HomeClient" });
                // if (user.credentials == "0") {
                //     this.$router.push({ name: "HomeClient" });
                // } else if (user.credentials == "5") {
                //     this.$router.push({ name: "HomeClientGroup" });
                // } else if (user.credentials == "10") {
                //     this.$router.push({ name: "HomeAdmin" });
                // }
            } else if (response.result == "1") {
                this.errorMessage = "Nom d'utilisateur incorrect";
                this.loginErrorModal = true;
            } else if (response.result == "2") {
                this.errorMessage = "Mot de passe incorrect";
                this.loginErrorModal = true;
            } else if (response.result == "3") {
                this.errorMessage = "Erreur serveur, veuillez réessayer";
                this.loginErrorModal = true;
            }
        },
        base64ToHex: function (str) {
            for (
                var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = [];
                i < bin.length;
                ++i
            ) {
                let tmp = bin.charCodeAt(i).toString(16);
                if (tmp.length === 1) tmp = "0" + tmp;
                hex[hex.length] = tmp;
            }
            return hex.join("");
        },
    },
};
</script>
<style scoped>
.custom-h1 {
    font-size: 80px;
}
.custom-h2 {
    font-size: 50px;
}
.custom-hr {
    border: 3px solid #11a9b9;
}
.login {
    padding: 60px;
}
</style>
